import React, { useState, useEffect } from "react";

export default function Modal({ title, text, setShowInfoModal }) {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        setIsVisible(true);
    }, []);

    const closeModal = () => {
        setIsVisible(false);
        setTimeout(() => {
            setShowInfoModal(false);
        }, 200);
    };

    return (
        <div
            className={`fixed flex justify-center items-center inset-0 transition-colors duration-200 ${
                isVisible ? "bg-[#000000a1]" : "bg-[#00000000]"
            }`}
        >
            <div
                className={`bg-white max-w-[500px] p-8 rounded transition-transform duration-200 ${
                    isVisible ? "scale-100" : "scale-0"
                }`}
            >
                <div>
                    <h2 className="font-semibold text-lg text-center mb-4">
                        {title}
                    </h2>
                    <h2>{text}</h2>
                </div>
                <button
                    onClick={closeModal}
                    className="w-[150px] text-sm font-medium py-2 bg-[#2ec4b6] hover:bg-primary-hover px-4 rounded flex items-center justify-center gap-x-2 grow active:scale-95 text-white mx-auto mt-8"
                >
                    Verstanden
                </button>
            </div>
        </div>
    );
}

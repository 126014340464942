import React from "react";
import { IoMdTrash, IoMdRefresh } from "react-icons/Io";
import { HiPencil } from "react-icons/Hi";
import { AiOutlineCheck } from "react-icons/Ai";
import { VscChromeClose } from "react-icons/Vsc";

export default function Banner({
    banner,
    handleDeleteBannerClick,
    handleEditBannerClick,
    handleActivationButtonClick,
    handleChangeBannerIdButtonClick,
}) {
    return (
        <div className="bg-[#1f2937] text-white px-8 py-7 rounded md:flex md:items-center md:justify-between md:gap-x-8">
            <h3 className="text-lg font-medium">{banner.title}</h3>
            <div className="grid grid-cols-1 gap-y-4 mt-8 min-[520px]:gap-x-4 min-[650px]:grid-cols-2 min-[900px]:grid-cols-3 md:mt-0 min-[1100px]:grid-cols-4">
                <button
                    onClick={() => handleChangeBannerIdButtonClick(banner)}
                    className="text-sm font-medium py-2 bg-[#4b5563] hover:bg-[#667386] px-4 rounded flex items-center gap-x-2 grow active:scale-95"
                >
                    <IoMdRefresh size={20} />
                    Neu generieren
                </button>

                <button
                    onClick={() => handleDeleteBannerClick(banner)}
                    className="text-sm font-medium py-2 bg-[#4b5563] hover:bg-[#667386] px-4 rounded flex items-center gap-x-2 grow active:scale-95"
                >
                    <IoMdTrash size={20} />
                    Löschen
                </button>

                <button
                    onClick={() => handleEditBannerClick(banner)}
                    className="text-sm font-medium py-2 bg-[#4b5563] hover:bg-[#667386] px-4 rounded flex items-center gap-x-2 grow active:scale-95"
                >
                    <HiPencil size={20} />
                    Bearbeiten
                </button>

                {banner.is_current == 1 && (
                    <button
                        onClick={() =>
                            handleActivationButtonClick(banner, false)
                        }
                        className="min-w-[150px] text-sm font-medium py-2 bg-[#5046e5] hover:bg-[#3024e0] px-4 rounded flex items-center gap-x-2 grow active:scale-95 min-[900px]:col-span-full min-[900px]:justify-center min-[1100px]:col-span-1"
                    >
                        <VscChromeClose size={18} />
                        Deaktivieren
                    </button>
                )}

                {banner.is_current == 0 && (
                    <button
                        onClick={() =>
                            handleActivationButtonClick(banner, true)
                        }
                        className="min-w-[150px] text-sm font-medium py-2 bg-[#2ec4b6] hover:bg-primary-hover px-4 rounded flex items-center gap-x-2 grow active:scale-95 min-[900px]:col-span-full min-[900px]:justify-center min-[1100px]:col-span-1"
                    >
                        <AiOutlineCheck size={18} />
                        Aktivieren
                    </button>
                )}
            </div>
        </div>
    );
}

// import OverlayHandler from "./modules/OverlayHandler";

// let overlayHandler = new OverlayHandler();

import React from "react";
import ReactDOM from "react-dom/client";
import App from "./components/App";

const container = document.querySelector("#root");
if (container != null) {
    const root = ReactDOM.createRoot(container);
    root.render(
        <div className="font-open-sans">
            <App />
        </div>
    );
}

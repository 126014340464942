import React, { useEffect, useState } from "react";
import Banner from "../Banner";
import Modal from "../Modal";
import { IoMdAdd } from "react-icons/Io";
import { BsFillInfoCircleFill } from "react-icons/Bs";
import {
    useFetchBanner,
    createBanner,
    deleteBanner,
    editBanner,
    setCurrentBanner,
    changeBannerId,
} from "./bannerSectionUtils";
import Sidebar from "../Sidebar";

/**
 * Is used to show all banners and interact with them
 * from deleting, editing, creating, activating and deactivating them.
 */
export default function BannerSection() {
    const modalTitle = "Wichtig";

    const modalText =
        'Wenn Sie einen Banner aktivieren wird dieser Ihren Besuchern auf der Seite angezeigt. Sobald ein Besucher auf den Button "Weiter zur Seite" klickt, wird der Banner dem Besucher nicht mehr angezeigt. Möchten Sie den Banner verändern und nochmal allen Besuchern anzeigen, klicken Sie auf "Neu generieren". Jeder neu genertierte und erstellte Banner wird immer jedem Besucher angezeigt, auch Besuchern die alte Banner bereits gesehen haben.';

    /**
     * Whether or not to show the info modal
     */
    const [showInfoModal, setShowInfoModal] = useState(false);

    /**
     * Store the banners we receive from the backend
     * A state variable and setState function
     */
    const [banners, setBanners] = useFetchBanner();

    /**
     * Used to make the Sidebar visible or invisible
     */
    const [isSidebarVisible, setIsSideBarVisible] = useState(false);

    /**
     * If the sidebar gets opened, we pass the sidebar this banner, if its set
     * Otherwise the siebar gets not banner and is in creation mode for a new one
     */
    const [bannerToEdit, setBannerToEdit] = useState(null);

    /**
     * This is used as a warpper for setIsSideBarVisible because after the sidebar closes
     * from the editing mode of a existing banner, we have to set the bannerToEdit to null
     */
    const toggleSideBarVisbility = () => {
        setIsSideBarVisible(!isSidebarVisible);
        setBannerToEdit(null);
    };

    /**
     * This function handles all clicks on the delete button on a banner
     *
     * @param {object} bannerToDelete
     * @returns {void}
     */
    const handleDeleteBannerClick = async (bannerToDelete) => {
        deleteBanner(bannerToDelete, banners, setBanners);
    };

    /**
     * This function handles all clicks on the edit button on a banner
     *
     * @param {object} banner
     * @returns {void}
     */
    const handleEditBannerClick = (banner) => {
        setBannerToEdit(banner);
        setIsSideBarVisible(true);
    };

    /**
     * This function handles all clicks on the save button on the sidebar
     *
     * @param {string} title
     * @param {string} text
     */
    const handleSideBarSaveButtonClick = async (title, text) => {
        if (bannerToEdit == null) {
            let newBanner = await createBanner(
                title,
                text,
                banners,
                setBanners
            );
            setBanners([...banners, newBanner]);
        } else {
            await editBanner(bannerToEdit, title, text);
            let bannerIndex = banners.findIndex(
                (item) => bannerToEdit.id === item.id
            );
            let updatedBanners = [...banners];
            updatedBanners[bannerIndex] = {
                ...bannerToEdit,
                title: title,
                text: text,
            };
            setBanners(updatedBanners);
        }
    };

    /**
     * This function handles all clicks on the activate / deactivate button on a banner
     * If newValue is true, the activate button got clicked and if newValue is false,
     * the deactivate button got clicked.
     *
     * @param {*} banner
     * @param {*} newValue
     */
    const handleActivationButtonClick = async (banner, newValue) => {
        let previousActivatedBannerIndex = banners.findIndex(
            (banner) => banner.is_current == 1
        );

        let bannerIndex = banners.findIndex(
            (bannerItem) => bannerItem.id === banner.id
        );

        let previousActivatedBanner = banners[previousActivatedBannerIndex];

        if (newValue && previousActivatedBannerIndex > -1) {
            await setCurrentBanner(banner, newValue, previousActivatedBanner);
        } else {
            await setCurrentBanner(banner, newValue);
        }

        let updatedBanners = [...banners];

        if (previousActivatedBannerIndex > -1) {
            updatedBanners[previousActivatedBannerIndex] = {
                ...previousActivatedBanner,
                is_current: "0",
            };
        }

        updatedBanners[bannerIndex] = { ...banner, is_current: newValue };

        setBanners(updatedBanners);
    };

    const handleChangeBannerIdButtonClick = async (banner) => {
        let changedBanner = await changeBannerId(banner);
        if (changedBanner !== null) {
            let bannerIndex = banners.findIndex(
                (item) => item.id === banner.id
            );
            let updatedBanners = [...banners];
            updatedBanners[bannerIndex] = { ...banner, id: changedBanner.id };
            setBanners(updatedBanners);
        }
    };

    return (
        <section className="py-8 px-mobile md:px-tablet">
            {showInfoModal && (
                <Modal
                    setShowInfoModal={setShowInfoModal}
                    title={modalTitle}
                    text={modalText}
                />
            )}

            {isSidebarVisible && (
                <Sidebar
                    toggleSideBarVisbility={toggleSideBarVisbility}
                    banner={bannerToEdit}
                    handleSaveButtonClick={handleSideBarSaveButtonClick}
                />
            )}

            <div className="max-w-desktop mx-auto ">
                <div className="flex justify-between items-center pb-4 border-b border-b-gray-300">
                    <div className="flex flex-col gap-y-2">
                        <div className="flex gap-x-2 items-center">
                            <h2 className="text-xl font-semibold">Banner</h2>
                            <BsFillInfoCircleFill
                                onClick={() => setShowInfoModal(true)}
                                className="text-primary cursor-pointer"
                                size={20}
                            />
                        </div>
                        <p className="text-gray-600">
                            Verwalten Sie hier Ihre Banner
                        </p>
                    </div>

                    <div
                        onClick={() => setIsSideBarVisible(true)}
                        className="bg-primary text-white p-2 rounded-full hover:bg-primary-hover active:scale-95"
                    >
                        <IoMdAdd size={20} />
                    </div>
                </div>

                <div className="mt-6 flex flex-col gap-y-4">
                    {banners.length === 0 && (
                        <p className="">
                            Um einen neuen Banner hinzuzufügen klicken Sie bitte
                            auf das Plus Symbol
                        </p>
                    )}

                    {banners.map((banner, index) => (
                        <Banner
                            key={index}
                            banner={banner}
                            handleDeleteBannerClick={handleDeleteBannerClick}
                            handleEditBannerClick={handleEditBannerClick}
                            handleActivationButtonClick={
                                handleActivationButtonClick
                            }
                            handleChangeBannerIdButtonClick={
                                handleChangeBannerIdButtonClick
                            }
                        />
                    ))}
                </div>
            </div>
        </section>
    );
}

import React from "react";
import { CgProfile } from "react-icons/cg";

export default function Header() {
    return (
        <header className="py-6 px-mobile md:px-tablet border border-b-gray-300">
            <div className="flex justify-between items-center max-w-desktop mx-auto">
                <h1 className="font-semibold text-sm sm:text-lg">
                    Hotel - Heizungsbau
                </h1>
                <form action="logout" name="X-CSRF-TOKEN" method="POST">
                    <input
                        type="hidden"
                        name="_token"
                        value={window.csrfToken}
                    />
                    <button
                        type="submit"
                        className="bg-primary flex gap-x-3 items-center px-6 py-2 rounded text-white hover:bg-primary-hover active:scale-95"
                    >
                        <CgProfile size={20} />
                        <p>Logout</p>
                    </button>
                </form>
            </div>
        </header>
    );
}

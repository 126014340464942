import React, { useState, useEffect } from "react";
import BannerSection from "./BannerSelection/BannerSection";
import Header from "./Header";
import { Toaster } from "react-hot-toast";

const App = () => {
    return (
        <>
            <Toaster />
            <Header />
            <BannerSection />
        </>
    );
};

export default App;

import { useState, useEffect } from "react";
import toast from "react-hot-toast";

/**
 * Fetches all banners from the backend
 *
 * @returns {[object]} banners
 */
const useFetchBanner = () => {
    const [banners, setBanners] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            let response = await fetch("/banner", {
                method: "GET",
                headers: {
                    "Content-type": "application/json",
                },
            });
            let json = await response.json();
            setBanners(json);
        };
        fetchData();
    }, []);

    return [banners, setBanners];
};

/**
 * Updates a given banner in the backend
 * Does not change the bannerToEdit object
 *
 * @param {object} bannerToEdit
 * @param {string} newTitle
 * @param {string} newText
 */
const editBanner = async (bannerToEdit, newTitle, newText) => {
    bannerToEdit.title = newTitle;
    bannerToEdit.text = newText;

    const onSuccess = () => {
        toast.success("Änderungen wurden erfolgreich gespeichert.", {
            style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
            },
        });
    };

    const onError = () => {
        toast.error("Änderungen konnte nicht erfolgreich gespeichert werden", {
            style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
            },
        });
    };

    const response = await fetch("/edit-banner", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": window.csrfToken,
        },
        body: JSON.stringify({
            "banner-id": bannerToEdit.id,
            "banner-title": bannerToEdit.title,
            "banner-text": bannerToEdit.text,
        }),
    });

    const json = await response.json();

    if ("status" in json && json.status === "success") {
        onSuccess();
        return;
    }

    onError();
};

/**
 * Creates a new banner in the backend
 * Returns the new created banner
 *
 * @param {string} title
 * @param {string} text
 * @param {[object]} banners
 * @param {() => void} setBanners
 */
const createBanner = async (title, text, banners, setBanners) => {
    const onSuccess = (banners, setBanners, newBanner) => {
        toast.success("Neuer Banner erstellt", {
            style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
            },
        });
    };

    const onError = () => {
        toast.error("Neuer Banner konnte nicht erstellt werden", {
            style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
            },
        });
    };

    const response = await fetch("/create-banner", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": window.csrfToken,
        },
        body: JSON.stringify({
            "banner-title": title,
            "banner-text": text,
        }),
    });

    const json = await response.json();

    if ("status" in json && json.status === "success" && "banner" in json) {
        onSuccess(banners, setBanners, json.banner);
        return json.banner;
    }

    onError();
};

/**
 * Deletes a given banner in the backend
 * Does not delete the button from the banners in the frontend
 *
 * @param {object} bannerToDelete
 * @param {[object]} banners
 * @param {() => void} setBanners
 * @returns
 */
const deleteBanner = async (bannerToDelete, banners, setBanners) => {
    const onSuccess = (bannerToDelete, banners, setBanners) => {
        toast.success("Banner wurde erfolgreich gelöscht.", {
            style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
            },
        });
        const updatedBanners = banners.filter(
            (banner) => banner.id != bannerToDelete.id
        );
        setBanners(updatedBanners);
    };

    const onError = () => {
        toast.error("Banner konnte nicht gelöscht werden.", {
            style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
            },
        });
    };

    const response = await fetch("/delete-banner", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": window.csrfToken,
        },
        body: JSON.stringify({
            "banner-id": bannerToDelete.id,
        }),
    });

    const json = await response.json();

    if ("status" in json && json.status === "success") {
        onSuccess(bannerToDelete, banners, setBanners);
        return;
    }

    onError();
};

/**
 * Changes the banner id, so the banner can be seen by everyone again, also the people who saw it already
 *
 * @param {object} banner
 * @param {boolean} newValue
 * @param {object} oldBanner
 */
const changeBannerId = async (banner) => {
    const onSuccess = () => {
        const text = "Banner wurde neu generiert";
        toast.success(text, {
            style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
            },
        });
    };

    const onError = () => {
        toast.error("Banner konnte nicht neu generiert", {
            style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
            },
        });
    };

    const response = await fetch("/change-banner-id", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": window.csrfToken,
        },
        body: JSON.stringify({ "banner-id": banner.id }),
    });

    const json = await response.json();

    if ("status" in json && json.status === "success" && "banner" in json) {
        onSuccess();
        return json.banner;
    }

    onError();
    return null;
};

/**
 * Setts the banner to the current one which should be active and shown on the frontend
 * If a oldBanner is set, this one gets deactivated in the backend
 *
 * @param {objet} banner
 * @param {boolean} newValue
 * @param {object} oldBanner
 */
const setCurrentBanner = async (banner, newValue, oldBanner) => {
    const onSuccess = (newValue) => {
        const text = newValue ? "Banner aktiviert" : "Banner deaktiviert";
        toast.success(text, {
            style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
            },
        });
    };

    const onError = () => {
        toast.error("Banner konnte nicht aktiviert werden", {
            style: {
                borderRadius: "10px",
                background: "#333",
                color: "#fff",
            },
        });
    };

    const body = {
        "banner-id": banner.id,
        "is-current": newValue ? 1 : 0,
    };

    if (oldBanner) {
        body["old-banner-id"] = oldBanner.id;
    }

    const response = await fetch("/set-current-banner", {
        method: "POST",
        headers: {
            "Content-Type": "application/json",
            "X-CSRF-TOKEN": window.csrfToken,
        },
        body: JSON.stringify(body),
    });

    const json = await response.json();

    if ("status" in json && json.status === "success") {
        onSuccess(newValue);
        return;
    }

    onError();
};

export {
    useFetchBanner,
    editBanner,
    createBanner,
    deleteBanner,
    setCurrentBanner,
    changeBannerId,
};

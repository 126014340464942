import React, { useEffect, useState } from "react";
import { MdClose } from "react-icons/Md";
import toast from "react-hot-toast";

export default function Sidebar({
    toggleSideBarVisbility,
    banner,
    handleSaveButtonClick,
}) {
    let isEditMode = banner != null;

    /**
     * I want the sidebar to close, if the user clicks on the dimmed area
     * ,the most outstanding container, which is working nearly fine,
     * but if the user clicks on other parts and then drags his mouse on the dimmed area
     * and lets his mouse key up, the sidebar closes, even it was no click on the outter container.
     * So this is why we save mouseDownElement to make shure the mouseDown was also in the outter container
     */
    let mouseDownElement = null;

    const [title, setTitle] = useState(isEditMode ? banner.title : "");

    const [text, setText] = useState(isEditMode ? banner.text : "");

    const [isVisible, setIsVisbile] = useState(false);

    const handleTitleChange = (ev) => {
        setTitle(ev.target.value);
    };

    const handleTextChange = (ev) => {
        setText(ev.target.value);
    };

    const checkInputs = () => {
        if (title === "" || text === "") {
            toast.error("Bitte geben Sie einen Titel und Text ein");
            return;
        }

        if (banner != null && title === banner.title && text === banner.text) {
            toast.error("Sie haben keine Veränderungen vorgenommen");
            return;
        }

        handleSaveButtonClick(title, text);
        handleCloseSideBar();
    };

    const handleContainerClick = (ev) => {
        ev.stopPropagation();
        if (
            ev.target == ev.currentTarget &&
            mouseDownElement == ev.currentTarget
        ) {
            handleCloseSideBar();
        }
    };

    const handleCloseSideBar = () => {
        setIsVisbile(false);
        setTimeout(() => {
            toggleSideBarVisbility();
        }, 200);
    };

    useEffect(() => {
        setIsVisbile(true);
    }, []);

    return (
        <div
            onMouseDown={(ev) => (mouseDownElement = ev.target)}
            onClick={handleContainerClick}
            className={`fixed inset-0 transition-colors duration-200 ${
                isVisible ? "bg-[#000000a1]" : "bg-[#00000000]"
            }`}
        >
            <div
                className={`bg-white transition-transform duration-200 ${
                    isVisible ? "translate-x-0" : "translate-x-full"
                } absolute right-0 top-0 bottom-0 flex flex-col w-full max-w-[400px]`}
            >
                <div className="grow flex flex-col">
                    {/* Header */}
                    <div className="bg-primary text-white flex justify-between items-center py-4 px-8">
                        <h4 className="text-xl">
                            {isEditMode ? "Bearbeiten" : "Neu"}
                        </h4>
                        <MdClose
                            className="active:scale-75"
                            onClick={handleCloseSideBar}
                            size={25}
                        />
                    </div>
                    {/* Body */}
                    <div className="flex flex-col gap-y-7 px-8 py-6 grow">
                        <div className="flex flex-col gap-y-2">
                            <p className="text-sm font-semibold text-gray-700">
                                Titel
                            </p>
                            <input
                                className="border border-gray-300"
                                name="banner-title"
                                id="banner-title"
                                type="text"
                                value={title}
                                onChange={handleTitleChange}
                            />
                        </div>

                        <div className="flex flex-col gap-y-2 grow">
                            <p className="text-sm font-semibold text-gray-700">
                                Text
                            </p>
                            <textarea
                                className="border border-gray-300 resize-none grow"
                                name="banner-text"
                                id="banner-text"
                                type="text"
                                value={text}
                                onChange={handleTextChange}
                            />
                        </div>
                    </div>
                </div>

                {/* Footer */}
                <div className="px-4 py-8 border border-t-gray-300 flex gap-x-4">
                    <button
                        onClick={handleCloseSideBar}
                        className="grow py-2 px-4 rounded active:scale-95 border border-t-gray-300 hover:bg-gray-300"
                    >
                        Abbrechen
                    </button>
                    <button
                        onClick={checkInputs}
                        className="grow py-2 px-4 rounded active:scale-95 bg-primary hover:bg-primary-hover text-white"
                    >
                        Speichern
                    </button>
                </div>
            </div>
        </div>
    );
}
